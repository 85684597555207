<template>
  <div>
    <div id="lineHistory" style="width: 1200px;height:450px;"></div>
    <detail-modal :visible.sync="modalVisible" :detail-data="modalDetailData"></detail-modal>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import {getPatrolDailyInspectionLine, getPropertyInspectionListByCondition} from "A/patrol";
import DetailModal from "V/propertyInspection/inspectionRecord/DetailModal.vue";
export default {
  name: "PatrolLineHistory",
  components: {DetailModal},
  props: {
    lineData: {
      default: null
    }
  },
  data () {
    return {
      nodeData: [
        {
          name: '杨柳河',
          x: 20,
          y: 0,
        },
        {
          name: '凤溪河',
          x: 40,
          y: 0,
          // itemStyle: {
          //   normal: {
          //     color: 'green'
          //   },
          // },
        },
        {
          name: '南熏大道',
          x: 60,
          y: 0
        }, {
          name: '光华公园',
          x: 80,
          y: 0
        }, {
          name: '涌泉',
          x: 100,
          y: 0,
        }, {
          name: '凤凰大街',
          x: 120,
          y: 0,
        },
        {
          name: '马厂坝',
          x: 140,
          y: 0,
        },
        {
          name: '非遗博览园',
          x: 160,
          y: 0,
        },
        {
          name: '蔡桥',
          x: 180,
          y: 0,
        },
        {
          name: '中坝',
          x: 200,
          y: 20,
        },
        {
          name: '成都西站',
          x: 200,
          y: 20,
        },
        {
          name: '清江西路',
          x: 220,
          y: 20,
        },
      ],
      links:[
        {
          source: '杨柳河',
          target: '凤溪河',
        },
        {
          source: '凤溪河',
          target: '南熏大道',
          value: "300分钟",
          label: {
            show: true,
            formatter:function (params){
              return "测试"
            }
          },
        },
        {
          source: '南熏大道',
          target: '光华公园'
        }, {
          source: '光华公园',
          target: '涌泉'
        }, {
          source: '涌泉',
          target: '凤凰大街'
        },
        {
          source: '凤凰大街',
          target: '马厂坝',
        }, {
          source: '马厂坝',
          target: '非遗博览园',
        }, {
          source: '非遗博览园',
          target: '蔡桥'
        }, {
          source: '蔡桥',
          target: '中坝'
        }, {
          source: '中坝',
          target: '成都西站'
        }, {
          source: '成都西站',
          target: '清江西路'
        },
      ],
      modalVisible: false,
      modalShowType: 'detail',
      modalDetailData: null,
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init(){
      getPatrolDailyInspectionLine({...this.lineData,alldone:'1'}).then(res=>{
        if(res&&res.returncode==='0'){
          this.nodeData = res.nodeData
          this.links = res.links
          this.load()
        }
      })
    },
    // 使用index关联起来
    load () {
      let chartDom = document.getElementById('lineHistory')
      let myChart = echarts.init(chartDom)
      let that = this
      let option
      option = {
        title: {
          text: '巡查线路图'
        },
        tooltip: {},
        animationDurationUpdate: 1500,
        animationEasingUpdate: 'quinticInOut',
        series: [
          {
            type: 'graph',
            layout: 'none',
            symbolSize: 13,//节点大小为13
            roam: true,
            itemStyle: {//给所有节点的类型一个默认样式，特殊的可在其节点下单独修改样式
              normal: {
                color: "#FFFFFF",//颜色默认白色
                borderColor: "#009900"//边框默认绿色
              }
            },
            label: {//给所有的节点字体一个默认样式
              normal: {
                show: true,//显示
                position: "bottom",//下方显示
                color: "#000",//默认颜色灰色
                fontSize: 12,//字体样式
              },
            },
            lineStyle: {//给所有连线一个默认样式
              normal: {
                width: 2,
                color: "#009900"
              }
            },
            data:this.nodeData,
            links: this.links,
          }
        ]
      },

      // echarts图表的点击事件，可通过param参数确认点击的对象
      myChart.on("click", function (param) {
        if (param.dataType === "node") {
          if(param.data.hasOwnProperty("inspection_id")){
            getPropertyInspectionListByCondition({inspection_id:param.data.inspection_id}).then(res=>{
              if(res&&res.returncode==='0'){
                that.modalDetailData=res.item[0]
                that.modalShowType="detail"
                that.modalVisible=true
              }
            })
          }else{
            that.$message.info("该点位还未巡查")
          }
        } else {
          console.log("点击了边", param);
        }
      });
      option && myChart.setOption(option)
    },
  },
}
</script>