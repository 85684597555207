<template>
  <a-modal v-model="modalVisible" :title="modalTitle" :width="1200" :centered="true" :body-style="modalBodyStyle">
    <template slot="footer">
      <a-button type="primary" @click="closeModal">关闭</a-button>
    </template>
    <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" :model="formData" :label-col="{span:9}" :wrapper-col="{span:14}">
      <a-tabs type="card">
        <a-tab-pane key="1" tab="当前巡查">
          <h5 style="color: black">一、下方线路图中<span style="color: red">红色</span>点位代表<span style="color: red;">"异常"</span>，<span style="color: black">绿色点位代表"正常"，两点之间的连线上方时间代表两点之间耗时，</span><span style="color: red;">括号内为平均耗时，</span>一条线路至少要完整完成一次巡查才会有平均耗时</h5>
          <h5 style="color: black">二、巡查异常规则</h5>
          <h5 style="color: red"><span style="color: black">（1）</span>本次巡查前后两点之间的时长大于该两点平均巡查时长的2倍</h5>
          <h5 style="color: red"><span style="color: black">（2）</span>本次巡查签到时间不在巡查线路规定的最早起始时间和最晚结束时间之间</h5>
          <h5 style="color: red"><span style="color: black">（3）</span>图片拍摄时间和巡查时间相差超过24小时</h5>
          <patrol-line :key="currentKey" :lineData="detailData"></patrol-line>
        </a-tab-pane>
        <a-tab-pane key="2" tab="历史巡查">
          <artificial-history-patrol-line :key="currentKey" :lineData="detailData"></artificial-history-patrol-line>
        </a-tab-pane>
      </a-tabs>
    </a-form-model>
  </a-modal>
</template>
<script>
import moment from 'moment'
import {getCache, getItemFromArrayByKey} from "U/index";
import {mapGetters} from "vuex";
import deptselect from "@/mixins/deptselect";
import pagination from "@/mixins/pagination";
import {getPropertyInspectionListByCondition} from "A/patrol";
import artificialHistoryPatrolLine from "V/workCheck/patrol/patrolCheck/artificialHistoryPatrolLine.vue";
import patrolLine from "V/workCheck/patrol/patrolCheck/patrolLine.vue";
export default {
  props: {
    visible: {
      default: false
    },
    detailData: {
      default: null
    }
  },
  mixins: [deptselect,pagination],
  components: {
    artificialHistoryPatrolLine,
    patrolLine,
  },
  data() {
    return {
      moment,
      modalVisible: false,
      modalBodyStyle: {
        height: '680px',
        overflowY: 'auto',
      },
      formData: {
        monitorpointnum: '',
        monitorpointname: '',
        equipment_num: '',
        equipment_name: '',
        machine_room_num: '',
        machine_room_name: '',
        username: '',
        inspection_time: '',
        inspection_address: '',
        inspection_end_time:'',
        result_code:'',
        remark:'',
        inspection_interval:'',
      },
      queryParams: {
        monitorpointname:'',
        point_name:'',
        username:'',
        result_code:'',
        inspection_interval:'',
        starttime:'',
        endtime:'',
        inspection_exception:'',
      },
      tableColumns: [
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '巡查点名称',
          dataIndex: 'point_name',
          key: 'point_name',
          ellipsis: true,
        },
        {
          title: '巡查人员',
          dataIndex: 'username',
          key: 'username',
          ellipsis: true,
        },
        {
          title: '巡查签到时刻',
          dataIndex: 'inspection_time',
          key: 'inspection_time',
          ellipsis: true,
          scopedSlots: { customRender: 'inspection_time' }
        },
        {
          title: '巡查时长',
          dataIndex: 'inspection_interval',
          key: 'inspection_interval',
          ellipsis: true,
        },
        {
          title: '作业时间状态',
          dataIndex: 'inspection_exception',
          key: 'inspection_exception',
          ellipsis: true,
          scopedSlots: { customRender: 'inspection_exception' }
        },
        {
          title: '巡查结论',
          dataIndex: 'result_code',
          key: 'result_code',
          ellipsis: true,
          scopedSlots: { customRender: 'result_code' }
        },
        {
          title: '签到地址',
          dataIndex: 'inspection_address',
          key: 'inspection_address',
          ellipsis: true,
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          width: 70,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      lineData:{},
      currentKey:0,

      //操作按钮权限
      btnList:[],
      menu:{},
    }
  },
  computed: {
    ...mapGetters(['operationMenuTree']),
    modalTitle() {
      return '详情'
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.currentKey=this.currentKey+1
        this.initDetail();
        this.getTableData();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetData();
        this.$emit('update:visible', false);
      }
    },
  },
  created() {
    this.menu=getItemFromArrayByKey(this.operationMenuTree,"menuid",this.$route.params.menuid,"children")
    for(let i=0;i<this.menu.children.length;i++){
      this.btnList.push(this.menu.children[i].menuname)
    }
    this.modalVisible = this.visible;
  },
  methods: {
    initDetail() {
      if(this.detailData && this.detailData.inspection_id) {
        this.$nextTick(() => {
          this.formData = this.detailData
        })
      }
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getPropertyInspectionListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    resetData() {
      this.tableData = []
    },
    closeModal(){
      this.modalVisible=false
    },
    showModal(record){
    },
    customRow(record, index){
      return {
        on: { // 事件
          click: () => {
            this.modalDetailData=record
            this.modalShowType='detail'
            this.modalVisible=true
          },
        },
      }
    },
  }
}
</script>
<style lang="scss" scoped>
</style>