<template>
  <page-container :title="pageTitle">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner" style="margin: 10px -10px 10px -10px">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="巡查线路名称" prop="patroldailytemplatename" style="width: 25%">
              <a-input v-model="queryParams.patroldailytemplatename"></a-input>
            </a-form-model-item>
            <a-form-model-item label="开始巡查时段" prop="queryInstallDate" style="width: 25%">
              <a-range-picker v-model="queryInstallDate" :default-value="[moment(`${queryParams.starttime.substring(0,10)}`, 'YYYY-MM-DD'), moment(`${queryParams.endtime.substring(0,10)}`, 'YYYY-MM-DD')]"></a-range-picker>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
<!--              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>-->
            </div>
            <div v-show="showAdvanced" class="advanced-query">
<!--              <a-form-model-item label="线路状态" prop="status" style="width: 25%">-->
<!--                <a-select v-model="queryParams.status">-->
<!--                  <a-select-option value="">全部</a-select-option>-->
<!--                  <a-select-option value="0">使用中</a-select-option>-->
<!--                  <a-select-option value="1">作废</a-select-option>-->
<!--                </a-select>-->
<!--              </a-form-model-item>-->
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
<!--            <a-button type="primary" @click="showModal()"><a-icon type="plus"></a-icon>新建</a-button>-->
<!--            <a-button @click="exportModal" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出</a-button>-->
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :customRow="customRow" :data-source="tableData" row-key="inspection_id" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="inspection_time" slot-scope="value, record">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="status" slot-scope="value, record">{{value===1?"作废":"使用中"}}</span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.inspection_id">详情</a-menu-item>
                <!--                <a-menu-item :key="'edit-'+record.inspection_id">修改</a-menu-item>-->
                <!--                <a-menu-item :key="'delete-'+record.inspection_id">修改</a-menu-item>-->
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <a-modal v-model="modalVisible" width="1200px" height="600px">
      <template slot="footer">
        <a-button @click="modalVisible=false">关闭</a-button>
      </template>
      <h5 style="color: black">一、下方线路图中<span style="color: red">红色</span>点位代表<span style="color: red;">"异常"</span>，<span style="color: black">绿色点位代表"正常"，两点之间上方的连线的时间代表两点之间耗时，</span><span style="color: red;">括号内为平均耗时，</span>一条线路至少要完整完成一次巡查才会有平均耗时</h5>
      <h5 style="color: black">二、巡查异常规则</h5>
      <h5 style="color: red"><span style="color: black">（1）</span>本次巡查前后两点之间的时长大于该两点平均巡查时长的2倍</h5>
      <h5 style="color: red"><span style="color: black">（2）</span>本次巡查签到时间不在巡查线路规定的最早起始时间和最晚结束时间之间</h5>
      <h5 style="color: red"><span style="color: black">（3）</span>图片拍摄时间和巡查时间相差超过24小时</h5>
      <patrol-line-history :key="currentKey" :lineData="modalDetailData"></patrol-line-history>
    </a-modal>
  </page-container>
</template>
<script>
import { getItemFromArrayByKey, getTimeRange } from 'U'
import { mapState } from 'vuex'
import moment from 'moment'
import deptselect from '@/mixins/deptselect'
import pagination from '@/mixins/pagination'
import lineDetail from "V/workCheck/patrol/patrolCheck/artificialPatrolLineDetail.vue";
import {getPatrolDailyHistoryListByCondition} from "A/patrol";
import PatrolLineHistory from "V/workCheck/patrol/patrolCheck/patrolLineHistory.vue";
export default {
  name: 'artificialHistoryPatrolLine',
  mixins: [deptselect,pagination],
  components: {
    PatrolLineHistory,
    lineDetail,
  },
  props: {
    lineData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      showAdvanced: false,
      queryParams: {
        monitorpointname:'',
        patroldailytemplatename:'',
        status:'',
        starttime:'',
        endtime:'',
      },
      queryInstallDate: null,
      userdepidCascaderSelected: [],
      tableColumns: [
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '开始巡查时间',
          dataIndex: 'inspection_time',
          key: 'inspection_time',
          ellipsis: true,
          width: 140,
          scopedSlots: { customRender: 'inspection_time' }
        },
        {
          title: '巡查线路名称',
          dataIndex: 'patroldailytemplatename',
          key: 'patroldailytemplatename',
          ellipsis: true,
        },
        {
          title: '线路状态',
          dataIndex: 'status',
          key: 'status',
          ellipsis: true,
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '最早巡查开始时刻',
          dataIndex: 'starttime',
          key: 'starttime',
          ellipsis: true,
        },
        {
          title: '最晚巡查结束时刻',
          dataIndex: 'endtime',
          key: 'endtime',
          ellipsis: true,
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          width: 70,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      lineVisible:false,
      currentKey: 10,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return '历史巡查线路';
    },
  },
  watch: {
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
  },
  created() {
    let time = new Date();
    let monthNum = moment(time).format("YYYY-MM").slice(5);
    this.queryParams.starttime = moment(time).month(monthNum - 1).date(1).startOf("month").format("YYYYMMDD");
    this.queryParams.endtime = moment(time).month(monthNum - 1).date(1).endOf("month").format("YYYYMMDD");
    this.initDeptOptionsAll();
    this.getTableData();
  },
  methods: {
    resetQueryParams() {
      this.$refs.queryForm.resetFields();
      this.queryInstallDate = [];
      this.userdepidCascaderSelected=[]
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        monitorpointnum:this.lineData.monitorpointnum,
        patroldailytemplateid:this.lineData.patroldailytemplateid,
        // status:0,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getPatrolDailyHistoryListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    showModal(type,record){
      if(type=='detail'){
        this.modalDetailData=record
        this.modalShowType=type
        this.modalVisible=true
      }else if(type=='edit'){
        this.modalDetailData=record
        this.modalShowType=type
        this.modalVisible=true
      }
    },
    customRow(record, index){
      return {
        on: { // 事件
          click: () => {
            this.currentKey=this.currentKey+1
            this.modalDetailData=record
            this.modalShowType='detail'
            this.modalVisible=true
          },
        },
      }
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let inspection_id = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'inspection_id', inspection_id);
      if(type == 'detail') {
        this.modalDetailData=record
        this.modalShowType='detail'
        this.modalVisible=true
      }
    },
    exportModal(){
      this.$confirm('你确认要按照所选筛选条件导出文件吗？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.exportLog()
      }).catch(()=>{
      });
    },
    exportLog(){
      let params = {
        ...this.queryParams,
      }
      this.showLoading();
      exportPropertyInspectionExcel(params).then((res)=> {
        this.hideLoading();
        this.$message.success("导出成功")
      }).catch(()=>{
        this.hideLoading();
      })
    },
  }
}
</script>
<style lang="scss" scoped>
</style>